<template>
  <div class="home">
    <p>Don't you think she looks tired? Aw, I wanted to be ginger! I've never been ginger! And you, Rose Tyler! Fat lot
      of good you were! You gave up on me! Ooh, that's rude. Is that the sort of man I am now? Am I rude? Rude and not
      ginger. There was a war. A Time War. The Last Great Time War. My people fought a race called the Daleks, for the
      sake of all creation. And they lost. We lost. Everyone lost. They're all gone now. My family. My friends. Even
      that sky. Goodbye...my Sarah Jane! There's something else I've always wanted to say: Allons-y, Alonso!</p>
    <p>
      Don't you think she looks tired? It is! It's the city of New New York! Strictly speaking, it's the fifteenth New
      York since the original, so that makes it New-New-New-New-New-New-New-New-New-New-New-New-New-New-New New York.
      Oh, yes. Harmless is just the word: that's why I like it! Doesn't kill, doesn't wound, doesn't maim. But I'll tell
      you what it does do: it is very good at opening doors! There was a war. A Time War. The Last Great Time War. My
      people fought a race called the Daleks, for the sake of all creation. And they lost. We lost. Everyone lost.
      They're all gone now. My family. My friends. Even that sky.
    </p>
    <p>
      There was a war. A Time War. The Last Great Time War. My people fought a race called the Daleks, for the sake of
      all creation. And they lost. We lost. Everyone lost. They're all gone now. My family. My friends. Even that sky.
      Oh, yes. Harmless is just the word: that's why I like it! Doesn't kill, doesn't wound, doesn't maim. But I'll tell
      you what it does do: it is very good at opening doors! River, you know my name. You whispered my name in my ear!
      There's only one reason I would ever tell anyone my name. There's only one time I could...</p>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {}
}
</script>
