<template>
  <h1>GM view :: Cthulhu</h1>

</template>

<script>
export default {
  name: "SheetsCthulhu",
  props: ['slug'],
  setup(props) {
    return {
      props
    }
  }
}
</script>

<style scoped>

</style>